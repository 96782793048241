<template>
	<div class="checks" :class="{ editable }">
		<div class="sections">
			<button v-if="editable" class="addSection ba ph-5" @click="createSection">Add Section</button>
			<StickySweet class="section" containerClass="checks" v-for="section in data" :key="section.id">
				<div class="title">
					<span @click="editSection(section)">{{ section.title }}</span>
					<button
						v-if="!section.checks || !section.checks.length"
						class="x"
						@click="destroySection(section.id)"
					/>
				</div>
				<button v-if="editable" class="addCheck ba ph-5 mb-5" @click="createItem(section.id)">
					Add Checkbox
				</button>
				<div class="items">
					<div
						v-for="item in section.checks"
						:key="item.id"
						class="item"
						:class="{ checked: modelValue.includes(item.id) }"
					>
						<input
							type="checkbox"
							:checked="modelValue.includes(item.id)"
							@click.stop="toggleSelected(item.id)"
						/>
						<span @click="editItem(item)">{{ item.title }}</span>
						<button v-if="editable" class="x" @click="destroyItem(item.id)" />
					</div>
				</div>
			</StickySweet>
		</div>
	</div>
</template>

<script>
	import { uniq, without } from "lodash"
	import { ref, onMounted } from "vue"
	import { alertsList, checks, modals } from "@/store"
	import { StickySweet } from "@/components"
	import Edit from "./Edit"

	export default {
		name: "Checks",
		components: { StickySweet },
		props: {
			modelValue: { type: Array, default: () => [] },
			editable: { type: Boolean, default: false }
		},
		setup(props, ctx) {
			const alerts = alertsList()
			const store = checks()
			const editing = ref(false)

			const pushSelected = (ids) => {
				if (!Array.isArray(ids)) ids = [ids]
				const value = uniq([...props.modelValue, ...ids])
				ctx.emit("update:modelValue", value)
			}

			const popSelected = (ids) => {
				if (!Array.isArray(ids)) ids = [ids]
				const value = without(props.modelValue, ...ids)
				ctx.emit("update:modelValue", value)
			}

			const toggleSelected = (id) => {
				if (props.modelValue.includes(id)) popSelected(id)
				else pushSelected(id)
			}

			const editItem = async ({ id, title }) => {
				if (!props.editable) return
				const result = await modals().open.component({
					component: Edit,
					props: { text: title },
					size: "sm"
				})
				if (result) return store.updateItem(id, result)
			}

			const editSection = async ({ id, title }) => {
				if (!props.editable) return
				const result = await modals().open.component({
					component: Edit,
					props: { text: title },
					size: "sm"
				})
				if (result) return store.updateSection(id, result)
			}

			const createItem = async (sectionId) => {
				if (!props.editable) return
				const title = await modals().open.component({
					component: Edit,
					size: "sm"
				})
				if (title) return store.createItem({ sectionId, title })
			}

			const createSection = async () => {
				if (!props.editable) return
				const title = await modals().open.component({
					component: Edit,
					size: "sm"
				})
				if (title) return store.createSection({ title })
			}

			const destroyItem = (id) => {
				store.destroyItem(id).catch(() => alerts.push("Cannot delete checkbox", "neg"))
			}

			const destroySection = (id) => {
				store.destroySection(id).catch(() => alerts.push("Cannot delete section", "neg"))
			}

			onMounted(() => {
				store.fetch()
			})

			return {
				...store,
				toggleSelected,
				editItem,
				editSection,
				createItem,
				createSection,
				destroyItem
			}
		}
	}
</script>

<style lang="scss">
	.checks {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: scroll;
		.sections {
			display: flex;
			align-items: stretch;
		}
		.section {
			flex: 1;
		}
		.title {
			font-size: $fnt-lrg;
			font-weight: bold;
			color: $clr-prim;
			padding: $atom * 4 $atom * 2;
			position: sticky;
			top: 0;
			z-index: 10;
			background: $clr-neut;
			display: flex;
			align-items: center;
			gap: $atom * 4;
		}
		.item {
			display: flex;
			align-items: center;
			gap: $atom * 4;
			padding: $atom * 1 $atom * 2;
			input {
				min-width: 18px;
			}
			&.checked {
				color: $clr-prim;
			}
			.x {
				opacity: 0;
			}
			&:hover .x {
				opacity: 1;
			}
		}
		.addCheck {
		}
		.addSection {
			position: sticky;
			top: 45px;
			flex: 0.5;
			margin-right: $atom * 5;
		}
	}
</style>
