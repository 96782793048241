<template>
	<div class="exercises flx gap-2">
		<List
			@edit="pushExercise"
			ref="list"
			@inspectCategories="inspectCategories"
			@inspectChecks="inspectChecks"
			@inspectTags="inspectTags"
			@deleted="popExercise"
		/>

		<div v-if="categoryIds" class="metaWrapper">
			<div>
				<button @click="clearCategories" class="ml-a">Clear</button>
				<button @click="categoryIds = null">Close</button>
			</div>
			<Categories v-model="categoryIds" :key="categoryKey" />
			<div v-if="selected.length" class="apply">
				<div class="categories">
					<div v-for="(id, indx) in reducedCategories" :key="indx">
						<template v-if="categories[id]">{{
							getCategoryPath(id)
								.map((node) => node.title.split(";")[0])
								.join(" > ")
						}}</template>
					</div>
				</div>
				<button @click="applyCategories">
					Apply to:
					<div>
						<div v-for="(id, indx) in selected" :key="indx">{{ exercises[id].title }}</div>
					</div>
				</button>
			</div>
		</div>

		<div v-if="checkIds" class="metaWrapper">
			<div>
				<button @click="clearChecks" class="ml-a">Clear</button>
				<button @click="checkIds = null">Close</button>
			</div>
			<div><Checks v-model="checkIds" /></div>
			<div v-if="selected.length" class="apply">
				<div class="exerciseTags">
					<div v-for="id in checkIds" :key="id">
						{{ checks[id] ? checks[id].title.split(";")[0] : "" }}
					</div>
				</div>
				<button @click="applyChecks">
					Apply to:
					<div>
						<div v-for="(id, indx) in selected" :key="indx">{{ exercises[id].title }}</div>
					</div>
				</button>
			</div>
		</div>

		<div v-if="tags" class="metaWrapper">
			<div>
				<button @click="tags = []" class="ml-a">Clear</button>
				<button @click="tags = null">Close</button>
			</div>
			<div><Tags @select="(tag) => tags.unshift(tag)" /></div>
			<div v-if="selected.length" class="apply">
				<div class="exerciseTags">
					<div v-for="(tag, indx) in tags" :key="tag.id">
						<div>
							<span>{{ tag.title }}</span>
							<button class="x ml-2" @click="tags.splice(indx, 1)" />
						</div>
					</div>
				</div>
				<button @click="applyTags">
					Apply to:
					<div>
						<div v-for="(id, indx) in selected" :key="indx">{{ exercises[id].title }}</div>
					</div>
				</button>
			</div>
		</div>

		<Item
			v-for="exerciseId in exerciseIds"
			:key="exerciseId"
			:exerciseId="exerciseId"
			@close="popExercise"
			@saved="$refs.list.fetchExercises()"
		/>
	</div>
</template>

<script>
	import { uniq, without } from "lodash"
	import { ref, computed } from "vue"
	import { useRouter, useRoute } from "vue-router"
	import { foldAll } from "he-tree-vue"
	import { categories, checks, exercises, exercisesList, api, alertsList } from "@/store"
	import List from "./List"
	import Item from "./Item"
	import Categories from "@/views/Categories/Categories"
	import Checks from "@/views/Checks/Checks"
	import Tags from "@/views/Tags/Tags"

	export default {
		name: "Exercises",
		components: { Categories, Checks, List, Item, Tags },
		setup(props) {
			const router = useRouter()
			const route = useRoute()
			const alerts = alertsList()
			const list = ref(null)

			//			const store = exercises()
			const exerciseIds = computed({
				get() {
					return route.params.ids
						.split(",")
						.filter((v) => v)
						.map((v) => +v)
				},
				set(v) {
					if (v && Array.isArray(v)) router.push({ params: { ids: v.join(",") }, query: route.query })
					else router.push({ params: { ids: undefined } })
				}
			})
			const pushExercise = (exerciseId) => {
				exerciseIds.value = uniq([exerciseId, ...exerciseIds.value])
			}
			const popExercise = (exerciseId) => {
				exerciseIds.value = without(exerciseIds.value, exerciseId)
			}

			const categoryIds = ref(null)
			const categoryKey = ref(0)
			const inspectCategories = async (exerciseId) => {
				const { meta } = await exercises().fetchExercise(exerciseId)
				categoryIds.value = meta.categories.map((c) => c.id)
				checkIds.value = null
				tags.value = null
				categoryKey.value++
			}
			const clearCategories = () => {
				categoryIds.value = []
				exercisesList().selected.value = []
				categoryKey.value++
			}
			const reducedCategories = computed(() => {
				const ids = categoryIds.value || []
				const isParent = (id) =>
					ids.some((_id) => categories().flat.value[_id] && categories().flat.value[_id].parentId === id)
				return ids.filter((id) => !isParent(id))
			})
			const applyCategories = async () => {
				const json = {
					exerciseIds: exercisesList().selected.value,
					meta: { categories: reducedCategories.value }
				}
				await api.post(`admin/exercises/meta`, { json })
				list.value.fetchExercises()
				alerts.push("Categories Applied", "pos")
			}

			const checkIds = ref(null)
			const inspectChecks = async (exerciseId) => {
				const { meta } = await exercises().fetchExercise(exerciseId)
				checkIds.value = meta.checks.map((c) => c.id)
				categoryIds.value = null
				tags.value = null
			}
			const clearChecks = () => {
				checkIds.value = []
				exercisesList().selected.value = []
			}
			const applyChecks = async () => {
				const json = {
					exerciseIds: exercisesList().selected.value,
					meta: { checks: checkIds.value }
				}
				await api.post(`admin/exercises/meta`, { json })
				list.value.fetchExercises()
				alerts.push("Checks Applied", "pos")
			}

			const tags = ref(null)
			const inspectTags = async (exerciseId) => {
				const { meta } = await exercises().fetchExercise(exerciseId)
				tags.value = meta.tags
				categoryIds.value = null
				checkIds.value = null
			}
			const applyTags = async () => {
				const json = {
					exerciseIds: exercisesList().selected.value,
					meta: { tags: uniq(tags.value.map((tag) => tag.id)) }
				}
				await api.post(`admin/exercises/meta`, { json })
				list.value.fetchExercises()
				alerts.push("Tags Applied", "pos")
			}

			Promise.all([categories().fetch(), checks().fetch()]).then(() => console.log("meta loaded"))

			return {
				pushExercise,
				popExercise,
				exerciseIds,
				categoryIds,
				checkIds,
				tags,
				inspectCategories,
				inspectChecks,
				inspectTags,
				clearChecks,
				categoryKey,
				categories: categories().flat,
				getCategoryPath: categories().getPath,
				selected: exercisesList().selected,
				exercises: exercisesList().exercises,
				reducedCategories,
				clearCategories,
				applyCategories,
				applyChecks,
				applyTags,
				list,
				checks: checks().flat
			}
		}
	}
</script>

<style lang="scss">
	.exercises {
		overflow: scroll !important;
		/*	scroll-snap-type: x mandatory; */
		> div {
			flex: 1;
			height: 100%;
			min-width: 500px;
			/*		scroll-snap-align: end; */
		}

		.metaWrapper {
			position: relative;
			overflow: scroll;
			display: grid;
			grid-template-rows: auto 1fr auto;
			> div {
				position: relative;
			}
			> div:first-child {
				height: $ctrl-ht;
				margin-bottom: $atom * 5;
				display: flex;
				gap: $atom * 4;
				> button {
					padding: 0 $atom * 5;
					border: 1px solid $clr-brdr;
				}
			}
			.apply {
				position: sticky;
				bottom: 0;
				z-index: 10;
				background: $clr-neut;
				display: grid;
				grid-gap: $atom * 5;
				padding-top: $atom * 5;
				.categories > div {
					font-size: $fnt-sml;
				}
				.exerciseTags > div {
					display: inline-block;
					margin: 1px;
					background: #fff;
					padding: $atom $atom * 4;
					> div {
						display: flex;
						align-items: center;
					}
				}
				> button {
					width: 100%;
					height: auto;
					background: $clr-pos;
					color: #fff;
					padding: $atom * 5;
					margin-bottom: $atom * 5;
					> div {
						margin-top: $atom * 5;
						white-space: wrap;
						display: grid;
						grid-gap: 2px;
						grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
						> div {
							border-radius: 3px;
							font-size: $fnt-sml;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							background: $clr-fcs;
							color: #000;
						}
					}
				}
			}
		}
	}
</style>
