<template>
	<div class="exerciseItem" :class="{ expand: item && item.lang !== 'en' }">
		<template v-if="item">
			<div class="header flx gap-2">
				<Spinner v-if="uploading" class="mh-a" />
				<template v-else>
					<Richbutton :working="updating" :theme="unsaved ? 'prim' : ''" class="ba" @click="save(item.id)"
						>Save</Richbutton
					>
					<div class="flx-1 flx aln-cntr jst-cntr">{{ item.title }}</div>
					<div class="flx">
						<Richbutton :theme="item.lang === 'en' ? 'prim' : ''" @click="switchLang('en')">En</Richbutton>
						<Richbutton :theme="item.lang === 'fr' ? 'prim' : ''" @click="switchLang('fr')">Fr</Richbutton>
					</div>
					<Richbutton class="ba" @click="$emit('close', item.id)">Close</Richbutton>
				</template>
			</div>
			<div class="body">
				<div class="title">
					<input type="text" v-model="item.title" placeholder="Title..." />
					<div v-if="item.lang !== 'en'" v-html="item.titleEn" />
				</div>
				<div v-if="item.lang === 'en'" class="meta">
					<div @click="showCategories">
						<div class="title">Categories</div>
						<div class="items categories">
							<div v-for="(id, indx) in itemCategories" :key="indx">
								<template v-if="categories[id]">{{
									getCategoryPath(id)
										.map((node) => node.title.split(";")[0])
										.join(" > ")
								}}</template>
							</div>
						</div>
					</div>
					<div @click="showChecks">
						<div class="title">Checks</div>
						<div class="items">
							<div v-for="(check, indx) in item.meta.checks" :key="indx">
								{{ checks[check.id].title.split(";")[0] }}
							</div>
						</div>
					</div>
					<div @click="showTags">
						<div class="title">Tags</div>
						<div class="items">
							<div v-for="(tag, indx) in item.meta.tags" :key="indx">{{ tag.title }}</div>
						</div>
					</div>
				</div>
				<div class="thumbnail">
					<div v-if="item.thumbnailId" class="preview">
						<img :src="item.thumbnail.url" />
						<button class="x" @click="item.thumbnailId = null" />
					</div>
					<Upload
						@upload="(files) => uploadThumbnail(item.id, files)"
						key="upload"
						:multiple="false"
						class="static ba"
					>
						<div
							v-if="['Uploading', 'Processing'].includes(item.uploadStatus)"
							class="spinnerWrapper ctrls"
						>
							<div class="status ml-5 mr-a">{{ item.uploadStatus }}</div>
							<Spinner class="ml-5" />
						</div>
						<template v-else>Thumbnail<br />PNG (40:37)</template>
					</Upload>
				</div>
				<div class="instructions">
					<Richtext v-model="item.instructions" />
					<div v-if="item.lang !== 'en'" class="en rich pa-5" v-html="item.instructionsEn" />
				</div>
				<SlickList axis="xy" v-model:list="item.resources" :distance="5" class="exerciseResources">
					<SlickItem v-for="(resource, indx) in item.resources" :key="indx" :index="indx">
						<div class="exerciseResource">
							<div class="preview">
								<template v-if="resource.resourceId">
									<a
										v-if="resource.resource.type === 'stockVideo'"
										:href="resource.resource.url"
										target="_blank"
										draggable="false"
									>
										<img :src="resource.resource.posterUrl" draggable="false" />
									</a>
									<img v-else :src="resource.resource.url" draggable="false" />
									<button class="x" @click="item.resources.splice(indx, 1)" />
									<div v-if="resource.resource.type === 'stockVideo'" class="videoTag">VIDEO</div>
								</template>
								<div
									v-else-if="['Uploading', 'Processing'].includes(resource.uploadStatus)"
									class="spinnerWrapper ctrls"
								>
									<div class="status ml-5 mr-a">{{ resource.uploadStatus }}</div>
									<Spinner class="mr-5" />
								</div>
							</div>
							<textarea class="caption" v-model="resource.caption" placeholder="Caption..." />
							<div v-if="item.lang !== 'en'" class="en" v-html="resource.captionEn" />
						</div>
					</SlickItem>
					<Upload
						@upload="(files) => uploadResources(item.id, files)"
						key="upload"
						:multiple="true"
						:max="10 - item.resources.length"
						class="static ba mt-5"
						>Resources<br />PNG (4:3) &mdash; MOV (16:9)</Upload
					>
				</SlickList>
			</div>
		</template>
		<div v-else class="absTl fill cntr">
			<Spinner />
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from "vue"
	import { SlickList, SlickItem } from "vue-slicksort"

	import { /*api, alertsList, modals, */ exercises, categories, checks, modals } from "@/store"
	import { Richbutton, Richtext, Spinner, Upload } from "@/components"
	import EditCategories from "./EditCategories"
	import EditChecks from "./EditChecks"
	import EditTags from "./EditTags"

	export default {
		name: "ExerciseItem",
		components: { Richbutton, Richtext, SlickList, SlickItem, Spinner, Upload },
		props: {
			exerciseId: { type: Number, required: true }
		},
		setup(props, ctx) {
			const store = exercises()
			const modalStore = modals()
			const categoryStore = categories()

			const item = computed(() => store.exercises.value[props.exerciseId])

			const saved = ref("")

			const unsaved = computed(() => saved.value !== JSON.stringify(item.value))

			const itemCategories = computed(() => {
				const ids = item.value.meta.categories.map((c) => c.id)
				const isParent = (id) =>
					ids.some((_id) => categoryStore.flat.value[_id] && categoryStore.flat.value[_id].parentId === id)
				return ids.filter((id) => !isParent(id))
			})

			const fetch = async (lang) => {
				await store.fetchExercise(props.exerciseId, lang)
				saved.value = JSON.stringify(item.value)
			}

			const save = async (execiseId) => {
				await store.updateExercise(execiseId, {
					categories: itemCategories.value,
					checks: item.value.meta.checks.map((v) => v.id),
					tags: item.value.meta.tags.map((v) => v.id)
				})
				saved.value = JSON.stringify(item.value)
				ctx.emit("saved")
			}

			const switchLang = async (lang) => {
				if (unsaved.value) await save(props.exerciseId)
				return fetch(lang)
			}

			const showCategories = async () => {
				const categoryIds = await modalStore.open.component({
					component: EditCategories,
					props: { categoryIds: item.value.meta.categories.map((i) => i.id) },
					size: "sm"
				})
				if (categoryIds) item.value.meta.categories = categoryIds.map((id) => ({ id }))
			}

			const showChecks = async () => {
				const checkIds = await modalStore.open.component({
					component: EditChecks,
					props: { checkIds: item.value.meta.checks.map((i) => i.id) },
					size: "md"
				})
				if (checkIds) item.value.meta.checks = checkIds.map((id) => ({ id }))
			}

			const showTags = async () => {
				const tags = await modalStore.open.component({
					component: EditTags,
					props: { tags: item.value.meta.tags },
					size: "md"
				})
				if (tags) item.value.meta.tags = tags
			}

			onMounted(() => {
				if (!item.value) fetch()
			})

			return {
				...store,
				item,
				unsaved,
				switchLang,
				save,
				showCategories,
				showChecks,
				showTags,
				categories: categoryStore.flat,
				getCategoryPath: categoryStore.getPath,
				itemCategories,
				checks: checks().flat
			}
		}
	}
</script>

<style lang="scss">
	.exerciseItem {
		display: grid;
		grid-template-rows: auto 1fr;
		position: relative;
		grid-gap: $atom * 5;
		overflow: scroll;

		&.expand {
			min-width: 800px;
		}

		.header {
			position: sticky;
			top: 0;
			z-index: 10;
			background: $clr-neut;
		}

		.body {
			background: #fff;
			border: 1px solid $clr-brdr;
			border-bottom: none;
			padding: $atom * 5;
			display: grid;
			grid-gap: $atom * 5;
		}

		.title {
			display: flex;
			gap: $atom * 5;
			> input,
			> div {
				flex: 1;
			}
			> div {
				display: flex;
				align-items: center;
			}
		}

		.meta {
			margin-bottom: $atom * 5;
			display: flex;
			flex-wrap: wrap;
			gap: $atom * 5;
			padding: 0 $atom * 4;
			> div {
				cursor: pointer;
				flex: 1;
				&:first-child {
					flex: 0 0 100%;
				}
			}
			.items > div {
				font-size: $fnt-sml;
				padding: 2px 5px;
				background: $clr-neut-l1;
				border-radius: 2px;
				margin-right: 1px;
			}
			.items:not(.categories) > div {
				display: inline-block;
			}
		}

		.instructions {
			display: flex;
			gap: $atom * 5;
			> div {
				flex: 1;
			}
			.en {
				margin-top: 42px;
			}
		}

		.thumbnail {
			display: flex;
			gap: $atom * 5;
			.preview {
				position: relative;
				width: 150px;
				height: 150px;
				img {
					@include absTl;
					@include fill;
					object-fit: cover;
				}
				button {
					@include absTr;
					@include mt(2);
					@include mr(2);
				}
			}
			.upload {
				flex: 1;
			}
		}
	}

	.exerciseResources {
	}

	.exerciseResource {
		z-index: 10;
		display: flex;
		> .preview {
			flex: 0;
			min-width: 150px;
			height: 112.5px;
			background: $clr-neut-l1;
			position: relative;
			cursor: grab;
			img {
				@include absTl;
				@include fill;
				object-fit: cover;
			}
			button {
				@include absTr;
				@include mt(2);
				@include mr(2);
			}
			.spinnerWrapper {
				@include absTl;
				width: 100%;
				.status {
					font-size: $fnt-sml;
					color: $clr-prim;
					font-weight: bold;
				}
				.spinner {
					flex: 0;
					flex-basis: 24px;
				}
			}
		}
		.en {
			flex: 1;
			@include pa(5);
		}
		.caption {
			flex: 1;
			font-size: $fnt-std;
			@include pa(5);
		}
		.videoTag {
			position: absolute;
			bottom: 0;
			right: 0;
			font-size: $fnt-sml;
			background: $clr-fcs;
			padding: 3px 6px;
		}
	}
</style>
