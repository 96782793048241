<template>
	<div class="header">Checks</div>
	<div class="body">
		<Checks v-model="ids" />
	</div>
	<div class="footer">
		<button @click="close()">Close</button>
		<Richbutton v-if="canApply" theme="prim" @click="close(ids)"> Apply </Richbutton>
	</div>
</template>

<script>
	import { ref, computed } from "vue"
	import { Richbutton } from "@/components"
	import Checks from "@/views/Checks/Checks"

	export default {
		name: "EditCategories",
		components: { Checks, Richbutton },
		props: {
			checkIds: { type: Array, required: true }
		},
		emits: ["close"],
		setup(props, cntxt) {
			const close = (result) => cntxt.emit("close", result)
			const ids = ref([...props.checkIds])
			const canApply = computed(() => JSON.stringify(ids.value) !== JSON.stringify(props.checkIds))
			return {
				ids,
				close,
				canApply
			}
		}
	}
</script>

<style lang="scss"></style>
