<template>
	<div class="header">Tags</div>
	<div class="body">
		<Tags @select="push" />
		<div class="exerciseTags">
			<div v-for="(tag, indx) in value" :key="tag.id">
				<div>
					<span>{{ tag.title }}</span>
					<button class="x ml-2" @click="value.splice(indx, 1)" />
				</div>
			</div>
		</div>
	</div>
	<div class="footer">
		<button @click="close()">Close</button>
		<Richbutton v-if="canApply" theme="prim" @click="close(value)"> Apply </Richbutton>
	</div>
</template>

<script>
	import { ref, computed } from "vue"
	import { Richbutton } from "@/components"
	import Tags from "@/views/Tags/Tags"

	export default {
		name: "EditTags",
		components: { Tags, Richbutton },
		props: {
			tags: { type: Array, required: true }
		},
		emits: ["close"],
		setup(props, cntxt) {
			const close = (result) => cntxt.emit("close", result)
			const value = ref([...props.tags])
			const canApply = computed(() => {
				const a = props.tags.map((v) => v.id)
				const b = value.value.map((v) => v.id)
				return !(a.every((v) => b.includes(v)) && b.every((v) => a.includes(v)))
			})
			const push = (tag) => {
				if (!value.value.find((v) => v.id === tag.id)) value.value.unshift(tag)
			}
			return {
				value,
				close,
				canApply,
				push
			}
		}
	}
</script>

<style lang="scss">
	.modalContainer .exerciseTags {
		display: grid;
		grid-gap: $atom;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		> div {
			background: $clr-fcs;
			border-radius: 3px;
			> div {
				display: flex;
				align-items: center;
				min-height: $ctrl-ht;
			}
			padding: 0 $atom * 5;
		}
	}
</style>
