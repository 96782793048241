<template>
	<div class="exerciseList">
		<div class="flx gap-2">
			<Richbutton :working="creating" theme="pos" @click="create">New Exercise</Richbutton>
			<input type="search" v-model="search" placeholder="Filter Exercises..." class="search flx-1 ba" />
			<Pagination :totalPages="totalPages" :page="paged" :gutter="1" @click="(v) => (page = v)" class="flx-0" />
		</div>
		<DataTable :items="xList" class="bh bt mt-5">
			<template v-slot:thead>
				<tr>
					<th />
					<th>id</th>
					<th class="stickLeft">Exercises (Total: {{ total }})</th>
					<th>fr</th>
					<th>Categories</th>
					<th>Checks</th>
					<th>Tags</th>
					<th>Modified</th>
				</tr>
			</template>
			<template v-slot:tbody="{ item }">
				<tr
					@click="(e) => onClickExercise(item.id, e)"
					@contextmenu.prevent="(e) => onClickExercise(item.id, e)"
					@dblclick="$emit('edit', item.id)"
					:class="{ selected: selected.includes(item.id) }"
				>
					<td class="min" @click.stop>
						<input type="checkbox" :checked="item.visible" @click.stop="toggleVisible(item.id)" />
					</td>
					<td class="ts" @click.stop @dblclick.stop>
						<Spinner v-if="updating === item.id" />
						<template v-else>{{ item.id }}</template>
					</td>
					<td class="stickLeft">
						<div class="title">
							<img :src="item.thumbnail" />
							<div>{{ item.title }}</div>
						</div>
					</td>
					<td><span class="emoji">👍</span></td>
					<td class="meta" @click="(e) => fetchMeta(e, 'inspectCategories', item.id)">{{ item.catCount }}</td>
					<td class="meta" @click="(e) => fetchMeta(e, 'inspectChecks', item.id)">{{ item.checkCount }}</td>
					<td class="meta" @click="(e) => fetchMeta(e, 'inspectTags', item.id)">{{ item.tagCount }}</td>
					<td class="ts">{{ item.updated.split("T")[0] }}</td>
				</tr>
			</template>
			<template v-slot:noResults> No results </template>
		</DataTable>
		<Transition name="slideDown">
			<ContextMenu v-if="contextMenu" :pos="contextMenu" @close="contextMenu = false">
				<header>{{ contextMenu.item.title }}</header>
				<button @click="$emit('edit', contextMenu.item.id)">Edit</button>
				<button @click="bump(contextMenu.item.id)">Bump</button>
				<button @click="duplicate(contextMenu.item.id)">Duplicate</button>
				<button @click="delExercise(contextMenu.item)">Delete</button>
			</ContextMenu>
		</Transition>
	</div>
</template>

<script>
	import { debounce } from "lodash"
	import { ref, computed, watch, onMounted } from "vue"
	import { useRouter, useRoute } from "vue-router"
	import { api, alertsList, modals, exercisesList } from "@/store"
	import { ContextMenu, DataTable, Pagination, Richbutton, Spinner } from "@/components"

	export default {
		name: "ExercisesList",
		components: { ContextMenu, DataTable, Pagination, Richbutton, Spinner },
		props: {},
		setup(props, ctx) {
			const router = useRouter()
			const route = useRoute()
			const modalStore = modals()
			const alerts = alertsList()

			const store = exercisesList()
			const total = ref(0)
			const page = computed({
				get() {
					return +route.query.page || 1
				},
				set(v) {
					router.push({ query: { page: v }, params: route.params })
				}
			})
			const paged = ref(1)
			const perPage = ref(50)
			const totalPages = computed(() => Math.ceil(total.value / perPage.value))
			const xIds = ref([])
			const xList = computed(() => xIds.value.map((id) => store.exercises.value[id]).filter((v) => v))
			const search = ref("")
			const searched = ref("")
			let pendingFetch
			const fetchExercises = async (p) => {
				if (pendingFetch && typeof pendingFetch.cancel === "function") pendingFetch.cancel()
				const filter = { page: p || page.value }
				if (search.value) filter.search = search.value
				const result = await store.fetchExercises(filter)
				if (result) {
					searched.value = result.search
					total.value = result.total
					xIds.value = result.ids
					paged.value = result.page
					if (page.value !== paged.value) page.value = paged.value
				}
			}
			const debouncedFetchExercises = debounce(fetchExercises, 500)
			watch(search, () => (pendingFetch = debouncedFetchExercises(1)))
			watch(page, () => {
				if (page.value !== paged.value) {
					fetchExercises()
				}
			})

			// contextMenu

			const contextMenu = ref(false)
			const onClickExercise = (exerciseId, e, showMenu) => {
				console.log(e)
				const { ctrlKey, metaKey } = e
				if (ctrlKey || metaKey) {
					store.toggleSelected(exerciseId)
				} else if (e.which === 3 || showMenu) {
					store.selected.value = [exerciseId]
					const { clientX, clientY } = e
					contextMenu.value = { x: clientX, y: clientY, item: store.exercises.value[exerciseId] }
				} else {
					store.selected.value = [exerciseId]
				}
			}

			const create = async () => {
				const { id } = await store.create()
				search.value = ""
				alerts.push("Created", "pos")
				await fetchExercises(1)
				store.selected.value = [id]
			}

			const duplicate = async (exerciseId) => {
				const { id } = await store.duplicate(exerciseId)
				search.value = ""
				alerts.push("Duplicated", "pos")
				await fetchExercises(1)
				store.selected.value = [id]
			}

			const bump = async (exerciseId) => {
				await store.bump(exerciseId)
				search.value = ""
				alerts.push("Bumped", "pos")
				await fetchExercises()
				store.selected.value = [exerciseId]
			}

			const delExercise = async (exercise) => {
				const confirm = await modalStore.open.dialog({
					header: "Delete Exercise",
					body: `
						You are about to delete the following Exercise:<br /><br />
						Exercise: <b>${exercise.title}</b><br /><br />
						<b>Note:</b><br />
						Exercises can only be deleted if all conditions are true:<br />
						Not referenced by any Exercise Programs
					`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (confirm) {
					const exerciseId = await store.del(exercise.id)
					if (exerciseId) {
						alerts.push("Deleted", "pos")
						ctx.emit("deleted", exerciseId)
						fetchExercises()
						//userIds.value = userIds.value.filter((id) => id !== userId)
					}
				}
			}

			const fetchMeta = (e, type, exerciseId) => {
				if (!e.metaKey && !e.ctrlKey) ctx.emit(type, exerciseId)
			}

			// actions
			/*
			const alerts = alertsList()
			const updateAttr = (orgId, attr, val) =>
				orgStore.updateOrg(orgId, { [attr]: val }).then((result) => {
					alerts.push("Done", "pos")
					return result
				})
			const showEdit = (orgId) => {
				modalStore.open.component({
					component: EditOrg,
					props: { id: orgId },
					size: "sm"
				})
			}
			*/

			onMounted(() => {
				fetchExercises()
				//				if (route.params.id) findOrgById(route.params.id)
				//				else fetchOrgs()
			})

			return {
				router,
				...store,
				total,
				page,
				paged,
				perPage,
				totalPages,
				xList,
				search,
				searched,
				fetchExercises,
				duplicate,
				bump,
				delExercise,
				create,
				contextMenu,
				fetchMeta,
				onClickExercise
			}
		}
	}
</script>

<style lang="scss">
	.exerciseList {
		/*
		position: sticky;
		left: 0;
		z-index: 10;
		*/
		display: grid;
		grid-template-rows: auto 1fr;
		.dataTable {
			th.stickLeft:before {
				content: "";
				display: block;
				width: 300px;
			}
		}
		.title {
			display: flex;
			align-items: center;
			gap: $atom * 5;
			img {
				min-width: 40px;
				height: 37px;
				flex: 0;
			}
			> div {
				flex: 1;
			}
		}
		.emoji {
			font-size: 20px;
		}
		.meta {
			cursor: zoom-in;
		}
	}
</style>
